p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1700px;
  }

}

body {
  background-image: url("./assets/img/background.jpeg");
  background-size: cover;
}

/**** Navbar Css ****/
.navbar {
  background-color: #ffffff00; 
  padding: 10px 0;
  transition: background-color 0.3s ease;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  z-index: 100;
}


.navbar p {
  font-size: 35px;
  font-weight: bold;
  margin: 0;
}

.navbar-link {
  color: #333;
  font-size: 22px;
  text-decoration: none;
  margin-right: 20px;
  transition: color 0.3s ease, transform 0.3s ease;
  position: relative;
}

.navbar-link::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0); 
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  z-index: -1;
}

.navbar-link:hover {
  transform: scale(1.05);
}

.navbar-link:hover::before {
  transform: scaleX(1);
  opacity: 1;
}

.navbar-link:hover {
  cursor: pointer;
}

.navbar-toggler {
  border: none;
  background: transparent;
  padding: 0;
  width: 40px;
  height: 40px;
  position: relative;
  transition: transform 0.3s ease;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-toggler .icon-bar {
  background: #333; 
  display: block;
  width: 24px;
  height: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s ease;
}

.navbar-toggler .icon-bar:last-child {
  width: 16px;
}


.navbar-toggler.open .icon-bar:nth-child(1) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.navbar-toggler.open .icon-bar:nth-child(2) {
  opacity: 0;
}

.navbar-toggler.open .icon-bar:nth-child(3) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.navbar-collapse {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff; 
  z-index: 99;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s ease;
  transform: translateY(-100%);
  padding: 15px;
}

.navbar-collapse.show {
  transform: translateY(0);
}

.navbar-toggler {
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
}

.icon-bar {
  width: 30px;
  height: 4px;
  background-color: #333;
  transition: 0.3s;
}

.open .icon-bar:nth-child(2) {
  opacity: 0;
}

.open .icon-bar:nth-child(1) {
  transform: rotate(-45deg) translate(-7px, 6px);
}

.open .icon-bar:nth-child(3) {
  transform: rotate(45deg) translate(-7px, -6px);
}

.navbar-collapse {
  background-color: #ffffffaf;
  padding-top: 10px;
}

.navbar-link {
  color: #333;
  padding: 8px 15px;
  display: block;
  transition: 0.2s;
}

.navbar-link:hover {
  color:#232323
}

.active {
  font-weight: bold;
}

@media (min-width: 768px) {
  .navbar-collapse {
    position: static;
    transform: translateY(0);
    box-shadow: none;
    background-color: transparent;
  }
}

.ms-auto{
  font-size: 18px;
}

/**** Banner Css ****/
.banner {
  margin-top: 0;
  padding: 260px 0 0 0;
}

.banner .tagline {
  font-weight: 800;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  color: #040303;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.6em;
  width: 96%;
}

.banner button {
  color: #ffffff;
  font-weight: 800;
  font-size: 26px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: table-row;
  padding: 2%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  border: 5px;
  margin: 5%;
  align-items: center;
}

.banner button.btn-primary {
  background-color: rgba(255, 255, 255, 0.1);
  border: 5px;

}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  animation: updown 3s linear infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}


/** Contact Css **/
.contact {
  align-items: center;
  padding: 70px 0;
}

.contact img {
  width: 100%;
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(81, 80, 80, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  color: #000000;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #fff;
  background-color: #000;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #00000042;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}
/**** Footer Css *****/

.footer {
  padding: 50px 0px 50px 0px;
  background: rgba(0, 0, 0, 0.496);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer img {
  width: 26%;
}

.footer p {
  font-weight: 400;
  font-size: 16px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

/* ****** Team **** */
.section-white {
	padding: 70px 0;
}

.section-white h2::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 25%;
  height: 10px; 
  background-color: rgb(0, 0, 0); 
  border-radius: 5px; 
  padding-top: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.team-item{
	background: #f4f5f667;
	text-align:center;
	margin:20px 0;
	padding:50px 20px 40px 20px;
	border-radius: 8px 8px;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.team-item:hover{
	background: #7082d1b5;
	color: rgb(0, 0, 0);
}

.team-item h3{
	margin:20px 0 1px 0!important;
	color:#000000;
	font-size:22px;
	font-weight: 700;
	text-transform:uppercase;
	transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-webkit-transition: all .25s ease-in-out;
}

.section-title {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.section-subtitle {
  margin-top: 22px;
 text-align: center;
	white-space: pre-line;
  color: #040303;
  font-size: 18 px;
  letter-spacing: 0.4px;
  line-height: 1.6em;
 }

.team-item:hover h3{
	color:#ddd;
}

.team-info {
	display: block;
	margin-bottom:0;
}

.team-info p{
	color:#363636;
	font-style:italic;
}
.team-info:hover p{
	color: #d5d5d5;
}

.team-info::after {
    background: #ffffff;
    background: -webkit-linear-gradient(135deg, #6B02FF 0%, #985BEF 100%);
    background: -o-linear-gradient(bottom right, #6B02FF, #985BEF);
    background: -moz-linear-gradient(bottom right, #008aff, #1ad2fd);
    background: linear-gradient(135deg, #6B02FF 0%, #985BEF 100%);
    display: inline-block;
    vertical-align: middle;
    content: "";
    width: 50px;
    height: 3px; 
}

.team-img{
	height: 200px;
  width: 200px;
	padding: 6px 6px;
  background-color: #dedede;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

.team-icon li a:hover{
	color:#dedede;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}


/* About */

.about-container {
  color: rgb(0, 0, 0);
  font-size: 22px;
  text-align: justify;
  padding: 70px 170px 170px 170px;
}

.about-container h2 {
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.about-container .aboutdes p {
  margin-bottom: 60px; /* Reduce the vertical margin for mobile devices */
  font-weight: 500;
  font-size: 24px;
  color: #040303;
  letter-spacing: 0.8px;
  line-height: 1.6em;
  padding: 170px 170px 170px 170px; 
}

.about-container h2::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 25%;
  height: 10px;
  background-color: rgb(0, 0, 0);
  border-radius: 5px;
  padding-top: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}


/* Media query for mobile devices with maximum width of 768px */
@media only screen and (max-width: 768px) {
  .about-container {
    font-size: 16px; 
    padding:10px 0;
  }

  .about-container h2 {
      font-size: 45px;
      font-weight: 700;
      margin-bottom: 30px;
  }

  .about-container .aboutdes p{
    font-size: 15px;
    margin-bottom: 10px;
    text-align: left;
    margin-bottom: 40px;
    /* padding: 10px ;  */
  }

  .about-container h2::after {
    width: 50%;
  }
}